@media print {
    /* styles here */

    .center_print{
        display: grid;
        align-items: center;
    }

    .displayNone {
        display: none !important;
        opacity: 0 !important;
        visibility: hidden !important;
        z-index: -100 !important;
        height: 0px !important;
        width: 0px !important;
    }
    .MuiBox-root .css-y5vkr9,
    .css-11evj4h {
        display: none !important;
        opacity: 0 !important;
        visibility: hidden !important;
        z-index: -100 !important;
        height: 0px !important;
        width: 0px !important;
    }
    .MuiBox-root .css-absfm6 {
        display: none !important;
        opacity: 0 !important;
        visibility: hidden !important;
        z-index: -100 !important;
        height: 0px !important;
        width: 0px !important;
    }
}
