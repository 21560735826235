.container {
    /* background-color: red; */
    /* height: 50px; */
    /* width: 50px; */
    overflow-x: scroll;
}

.rotated {
    /* background-color: red; */
    height: 20px !important;
    min-width: max-content !important;

    text-align: center;
    /* border-right: solid 1px grey; */
    /* min-width: 100px !important; */
}

.flexRow {
    display: flex;
    flex: 1;
    width: max-content !important;
    flex-direction: row;
}
.flexColumn {
    display: flex;
    flex-direction: column;
}

.stockCards {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    gap: 2rem;
    padding: 16px;
}

@media (max-width: 1200px) {
    .stockCards {
        grid-template-rows: repeat(3, 1fr);
    }
}

@media (max-width: 900px) {
    .stockCards {
        grid-template-rows: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .stockCards {
        grid-template-rows: 1fr;
    }
}
